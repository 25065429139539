import Form from 'components/Basic/Form'
import Navbar from 'components/Basic/Navbar'
import SuccessMessage from 'components/Basic/SuccessMessage'
import { useState } from 'react'
import styled from 'styled-components'
import { device } from 'styles/BreakPoints'
import Contact from 'components/Contact'
import { Box, SmallDevicesHidden } from 'styles/Global'

const FormSectionStyle = styled.section`
  width: 650px;
  margin: 0 auto;
  padding: 50px 30px 30px 30px;
  @media ${device.lg} {
    width: 550px;
  }
  @media ${device.md} {
    width: 500px;
    width: 100%;
  }
  @media ${device.sm} {
    font-size: 16px;
    padding:0px;
    margin: 10px;
    width: 95%;
  }
`

const FormWrapper = styled.div`
  margin-top: 5vh;
  @media ${device.lg} {
  }
  @media ${device.sm} {
    margin-top: 10px;
  }
`
const WelcomeMessage = styled.h1`
  font-size: 40px;
  font-weight: 900;
  line-height: 1.1;
  text-align:center;
  color: ${({ theme }) => theme.colors.text};
  margin-top: 6vh;

  @media ${device.md} {
    font-size: 60px;
  }
  @media ${device.sm} {
     font-size: 16px;
    margin-top: 60px;
    margin-top: 40px;
    text-align: center;
  }
`

const InfoMessage = styled.div`
  font-size: 18px;
  line-height: 1.8;
  color: ${({ theme }) => theme.colors.text};
  margin-top: 4vh;

  @media ${device.md} {
    font-size: 60px;
  }
  @media ${device.sm} {
    font-size: 16px;
    margin-top: 60px;
    margin-top: 40px;
    text-align: center;
  }
`

const FormSection = () => {
  const [addedInList, setAddedInList] = useState(false)

  const handleAddedInList = () => {
    setAddedInList(true)
  }

  return (
    <FormSectionStyle>
      <WelcomeMessage>About Us</WelcomeMessage>
      <InfoMessage>Got hacked or scammed? We’re experts at recovering funds and tokens from compromised wallets. Our expert team dives in fast to get your assets back where they belong. Trust us to turn your loss around and secure your digital stash!</InfoMessage>
    </FormSectionStyle>
  )
}

export default FormSection
