import HomeWrapper from 'components/Basic/HomeWrapper'
import { ThemeProvider } from 'styled-components'
import { GlobalStyles } from 'styles/Global'
import { basicLightTheme } from 'styles/themes/Basic/LightTheme'
import 'font-awesome/css/font-awesome.min.css';
import ClassicFooter from 'components/Footer/ClassicFooter'

function BasicLight() {
  return (
    <ThemeProvider theme={basicLightTheme}>
      <GlobalStyles />
      <HomeWrapper />
      <ClassicFooter />
    </ThemeProvider>
  )
}

export default BasicLight
