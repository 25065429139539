import { ReactComponent as Logo } from 'assets/icons/Anti.svg'
import React from 'react'
import styled from 'styled-components'
import { device } from 'styles/BreakPoints'
import { Box, SmallDevicesHidden } from 'styles/Global'

const SectionWrapper = styled.section`
  
`

const ContentWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 30px;
  @media ${device.lg} {
  font-size: 18px;
    padding-top: 20px;
  }
  @media ${device.sm} {
  padding: 0px;
  font-size: 18px;
   margin: 10px;
   overflow-wrap: break-word;
  }
`

const WelcomeMessage = styled.h1`
  font-size: 22px;
  font-weight: 600;
  line-height: 1.1;
  width: 90%;
  color: ${({ theme }) => theme.colors.text};
  margin-top: 10vh;

  @media ${device.md} {
  font-size: 18px;
    font-size: 60px;
  }
  @media ${device.sm} {
    font-size: 18px;
    margin-top: 60px;
    margin-top: 40px;
    overflow-wrap: break-word;
    
  }
`

const InfoMessage = styled.div`
  font-size: 18px;
  line-height: 1.8;
  text-align: justify;
  color: ${({ theme }) => theme.colors.text};
  margin-top: 4vh;
  overflow-wrap: break-word;

  @media ${device.md} {
  font-size: 18px;
    font-size: 60px;
  }
  @media ${device.sm} {
    font-size: 16px;
    margin-top: 60px;
    margin-top: 40px;
    
  }
`

const LogoWrapper = styled.div`
  width: 300px;
  color:black;
  svg {
    width: 100%;
    height: 100%;
    fill: black;
    path {
      fill: black;
    }
  }
  ${SmallDevicesHidden}
`

function WelcomeSection({ message }) {
  return (
    <SectionWrapper>
      <ContentWrapper>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <WelcomeMessage>Why Anti Sweeper?</WelcomeMessage>
        <InfoMessage>
        We are committed to being your trusted ally in the fight against crypto wallet auto sweepers, providing a robust solution for individuals affected by wallet compromises. Our mission is to help you recover your valuable assets and prevent future attacks, ensuring peace of mind in the often uncertain world of crypto.
        </InfoMessage>
        <WelcomeMessage>Bringing a bot to a bot fight</WelcomeMessage>
        <InfoMessage>
        Dealing with bots that steal your ETH before you can use it can feel pretty frustrating. Luckily, there's a solution called searcher-sponsored-tx. It lets you cover the transaction fees of a compromised address using another account. This works by submitting a transaction bundle: the first "sponsor" transaction sends ETH to an "executor" wallet, followed by transactions that use this ETH to pay for gas fees. It's a clever way to get around the bots and secure your funds!
        </InfoMessage>
      </ContentWrapper>
    </SectionWrapper>
  )
}

export default WelcomeSection
