// BotSweeperVictimForm.js
import { ReactComponent as Star } from 'assets/icons/star.svg';
import Button from 'components/Basic/Button';
import BasicInput from 'components/Input/BasicInput';
import useForm from 'hooks/useForm';
import styled from 'styled-components';
import { device } from 'styles/BreakPoints';
import { Box } from 'styles/Global';
import emailjs from 'emailjs-com';
import * as Yup from 'yup';

// Styles for the form
const FormStyle = styled.form`
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors.border || '#ddd'};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.background || '#fff'};
 
  @media ${device.sm} {
   margin-top: 50px;
    width: 100%;
  }
`;

// Styles for text with the Star icon
const TextStyle = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }
  @media ${device.sm} {
    display: none;
  }
`;

const validationSchema = Yup.object({
  name: Yup.string()
    .min(2, 'Name must be at least 2 characters')
    .required('Name is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  // WalletAddress: Yup.string()
  //   .matches(/^0x[a-fA-F0-9]{40}$/, 'Invalid Ethereum address')
  //   .required('Compromised address is required'),
  issueDescription: Yup.string()
    .min(10, 'Description must be supplied')
    .required('Description is required'),
  amount: Yup.number()
    .typeError('Amount must be a number')
    .positive('Amount must be positive')
    .required('Amount is required'),
  AssetType: Yup.string()
    .required('Asset type is required'),
});

const BotSweeperVictimForm = ({ handleAddedInList }) => {
  // Custom hook for managing form state and submission
  const { user, onChangeInput, onSubmitForm } = useForm({
    name: '',
    email: '',
    WalletAddress: '',
    issueDescription: '',
    amount:0,
    AssetType:'',
  });

  
  // Function to handle form submission
  const submitForm = (e) => {
    e.preventDefault();
   
    
    validationSchema.validate(user)
  .then(() => {
    console.log('Validation successful');

    sendEmail(e);

  })
  .catch((error) => {

    alert(error);
  });
  
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.send('service_3li0irr', 'template_9bnfkax', user, 'lYFUs5cZGQSjZP5sD')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        handleAddedInList(); // Callback function to handle the addition
      })
      .catch((err) => {
        console.error('FAILED...', err);
        alert('Failed to send email. Please try again later.');
      });
  };

  const { name, email, WalletAddress, issueDescription, amount, AssetType } = user;

  return (
    <FormStyle onSubmit={submitForm}>
      <BasicInput
        label="Name"
        name="name"
        value={name}
        onChange={onChangeInput}
        type="text"
        placeholder="Your Name"
        required
      />
      <BasicInput
        label="Email"
        name="email"
        value={email}
        onChange={onChangeInput}
        type="email"
        placeholder="Your Email"
        required
      />
      <BasicInput
        label="Compromised address(es)"
        name="WalletAddress"
        value={WalletAddress}
        onChange={onChangeInput}
        type="text"
        placeholder="Ethereum: 0x123...ABC"
      />
      <BasicInput
        label="Detailed Description"
        name="issueDescription"
        value={issueDescription}
        onChange={onChangeInput}
        type="text"
        placeholder="Describe the issue"
        required
      />
      <BasicInput
        label="Amount of assets (USD)"
        name="amount"
        value={amount}
        onChange={onChangeInput}
        type="number"
        placeholder="10,000"
        required
      />
      <BasicInput
        label="Assets Type"
        name="AssetType"
        value={AssetType}
        onChange={onChangeInput}
        type="text"
        placeholder="ETH, USDT, NFTs"
        required
      />
      <Box mt={30} flxRight smNone>
        <TextStyle>
          <Star />
          Required field
        </TextStyle>
      </Box>
      <Box mt={40} flxRight>
        <Button type="submit" title="Submit" />
      </Box>
    </FormStyle>
  );
};

export default BotSweeperVictimForm;
