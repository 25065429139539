import { routes } from 'navigations/Routes'
import { Route, Routes } from 'react-router-dom'
import  bgImage from 'assets/images/bgImage.png'

function App() {
  return (
    <div style={{ backgroundImage: `url(${bgImage})` }}>
      <Routes>
        {routes.map(({ path, component }) => (
          <Route key={path} path={path} element={component} />
        ))}
      </Routes>
    </div>
  )
}

export default App
