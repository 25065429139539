import { ReactComponent as FooterSVG } from 'assets/images/FooterSVG.svg'
import { device } from 'styles/BreakPoints'
import Contact from 'components/Contact'
import styled from 'styled-components'
import { Box, Container } from 'styles/Global'

const FooterStyle = styled.footer`
  margin-top: 80px;
  @media ${device.md} {
    margin-top: 10px;
  }
  @media ${device.sm} {
    margin-top:20px;
    margin-top: 10px;
  }
`

const FooterDetails = styled.div`
  display: flex;
  align-items: center;
`

const FooterSections = styled.section`
  display: flex;
  flex: 1;
  justify-content: space-around;
`

const Section = styled.div`
  ul {
    li {
      font-size: 20px;
      margin-top: 40px;
      color: ${({ theme }) => theme.colors.secondaryTextColor};
    }
  }
`

const SectionTitle = styled.h5`
  font-size: 28px;
  font-weight: 900;

   @media ${device.md} {
     font-size: 20px;
    font-weight: 900;
  }
  @media ${device.sm} {
     font-size: 16px;
    font-weight: 900;
  }
`

const Subtitle = styled.p`
  font-size: 20px;
  margin-top: 20px;

   @media ${device.md} {
     font-size: 18px;
  }
  @media ${device.sm} {
     font-size: 16px;
  }
`

const CopyRightText = styled.div`
  font-size: 20px;
  font-weight: normal;

   @media ${device.md} {
     font-size: 16px;
    font-weight: normal;
  }
  @media ${device.sm} {
     font-size: 16px;
    font-weight: normal;
  }
`

const TermsConditionsText = styled.div`
  font-size: 20px;
  font-weight: normal;
`

const Hr = styled.hr`
  position: relative;
`

const CopyRightContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-top: 25px;
  margin-bottom: 25px;
`
const TagStyle = styled.span`
  background: ${({ theme }) => theme.colors.tertiary};
  border-radius: 29px;
  padding: 6px 18px;
  font-size: 10px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.blackText};
  cursor: pointer;
  margin-left: 10px;
  margin-bottom: 10px;
`

function ClassicFooter() {
  return (
    <FooterStyle>
      <FooterDetails>
        <FooterSVG  style={{ width: '300px', height: '200px' }}/>
        <FooterSections>
          <Section>
            <SectionTitle>Have Any Query?</SectionTitle>
            <Subtitle>
                Don’t worry… we’re here for you.
            </Subtitle>
            <Box mt={30}>
              <Contact />
            </Box>
          </Section>
          {/* <Section>
            <SectionTitle>Company</SectionTitle>
            <ul>
              <li>About us</li>
              <li>Careers <TagStyle>Hiring!</TagStyle></li>
              <li>Contact us</li>
            </ul>
          </Section> */}
          {/* <Section>
            <SectionTitle>Resources</SectionTitle>
            <ul>
              <li>Blog</li>
              <li>Guides</li>
            </ul>
          </Section> */}
        </FooterSections>
      </FooterDetails>
      <>
        <Hr />
        <CopyRightContainer>
          <CopyRightText>Copyright © 2024 antisweeper</CopyRightText>
        </CopyRightContainer>
      </>
    </FooterStyle>
  )
}

export default ClassicFooter
