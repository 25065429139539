import { navbar } from 'config/navbar'
import styled from 'styled-components'
import { device } from 'styles/BreakPoints'
import { SmallDevicesHidden } from 'styles/Global'

const NavbarStyle = styled.nav`
  ul {
    display: flex;
    justify-content: space-between;
    li {
      font-size: 16px;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.primaryTextColor};
      cursor: pointer;
      @media ${device.md} {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  ${SmallDevicesHidden}
`
const TagStyle = styled.span`
  background: ${({ theme }) => theme.colors.tertiary};
  border-radius: 29px;
  padding: 6px 18px;
  font-size: 10px;
  font-weight: 700;
  color: white;
  cursor: pointer;
  margin-left: 10px;
  margin-bottom: 10px;
`

function Navbar() {
  return (
    <NavbarStyle>
      <ul>
        {navbar.map(({ title, subtitle }) => (
          <li key={title}>{title} 
          
          {subtitle ? (
            <TagStyle>Coming Soon!</TagStyle>
          ) : (
            <div></div>
          )}
        </li>
        ))}
      </ul>
    </NavbarStyle>
  )
}

export default Navbar
