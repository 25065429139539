export const navbar = [
  {
    title: 'Home',
    link: '/',
  },
  {
    title: 'Testimonials',
    subtitle: 'Coming Soon',
    link: '/',
  },
  {
    title: '',
    link: '/',
  },
  {
    title: '',
    link: '/',
  },
]
