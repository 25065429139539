import styled from 'styled-components'
import { device } from 'styles/BreakPoints'
import CollapseNavbar from '../CollapseNavbar'
import FormSection from '../FormSection'
import AboutSection from '../AboutSection'
import WelcomeSection from '../WelcomeSection'

const HomeWrapperStyle = styled.section`
  height: 100vh;
  position: relative;
  overflow: hidden;
  @media ${device.sm} {
    height: unset;
  }
`

const HomeSections = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;

  @media ${device.sm} {
    grid-template-columns: unset;
  }
`

function HomeWrapper() {
  return (
    <HomeWrapperStyle>
      <CollapseNavbar />
      <HomeSections>
        {/* Left side of the home page */}
        <WelcomeSection />
        {/* Right side of the home page */}
        <FormSection />
        <AboutSection />
      </HomeSections>
    </HomeWrapperStyle>
  )
}

export default HomeWrapper
