import { ReactComponent as Mail } from 'assets/icons/mail.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFeather } from '@fortawesome/fontawesome-free-solid'
import styled from 'styled-components'
import 'font-awesome/css/font-awesome.min.css';
import { device } from 'styles/BreakPoints'

const ContactStyle = styled.div`
  margin-top: 19px;
  width:300px;
  span {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.secondaryTextColor};
    margin-left: 23px;
  }
  @media ${device.md} {
    font-size: 14px;
  }
  @media ${device.sm} {
    font-size: 14px;
  }
`

function Contact() {
  return (
    <>
      <ContactStyle>
        <Mail />
        <span>antisweeper2022@gmail.com</span>
      </ContactStyle>
      <ContactStyle>
      <FontAwesomeIcon icon={faFeather} color='#e1762e'/>
        <span>Fill out the form, Please!</span>
      </ContactStyle>
    </>
  )
}

export default Contact
