
import BasicLight from 'screens/BasicLight'

const routes = [
  {
    path: '/',
    component: <BasicLight />,
  },
]

export { routes }
